<template>
  <b-modal
    id="update-collection-modal"
    title="Update Collection"
    no-fade
    no-close-on-backdrop
    ok-title="Save"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    :ok-disabled="loading"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <b-form-group label="Name" label-for="update-collection-name">
      <b-form-input
        id="update-collection-name"
        v-model="collectionName"
      />
    </b-form-group>
    <b-form-group label="Description" label-for="update-collection-description">
      <b-form-input
        id="update-collection-description"
        v-model="collectionDescription"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'UpdateCollection',
  props: {
    collection: {
      type: Object,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const collectionName = ref('')
    const collectionDescription = ref('')
    const loading = ref(false)
    const fillForm = () => {
      if (props.collection.name) {
        collectionName.value = props.collection.name
        collectionDescription.value = props.collection.description
      }
    }
    watch(() => props.collection, () => fillForm())
    fillForm()

    const onSubmit = () => {
      const payload = {
        id: props.collection.id,
        parent: props.parent,
        data: {
          name: collectionName.value,
          description: collectionDescription.value,
        },
      }
      loading.value = true
      store
        .dispatch('collections/updateCollection', payload)
        .then(() => emit('updated'))
        .catch(e => console.error(e))
        .finally(() => {
          loading.value = false
        })
    }

    const onCancel = () => {
      collectionName.value = props.collection.name
      collectionDescription.value = props.collection.description
    }

    return {
      collectionName,
      collectionDescription,
      loading,
      onSubmit,
      onCancel,
    }
  },
}
</script>
