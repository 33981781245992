var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-item-modal","title":"Add to Collection","no-close-on-esc":"","no-fade":"","no-close-on-backdrop":""},on:{"ok":_vm.addItems},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Discard ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v(" Add Item(s) ")])]}}])},[_c('b-form-group',{attrs:{"label":"Specifications","label-for":"select-spec-items"}},[_c('v-select',{staticClass:"select-class",attrs:{"id":"select-spec-items","placeholder":"Select Specification(s)","label":"title","multiple":"","options":_vm.specifications,"reduce":function (specObj) { return specObj.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var prefix = ref.prefix;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(title))]),_c('span',{staticClass:"font-small-3 text-muted"},[_vm._v(_vm._s(prefix))])])]}}]),model:{value:(_vm.selectedSpecifications),callback:function ($$v) {_vm.selectedSpecifications=$$v},expression:"selectedSpecifications"}})],1),_c('b-form-group',{attrs:{"label":"Behaviour Trees","label-for":"select-bts-items"}},[_c('v-select',{staticClass:"select-class",attrs:{"id":"select-bts-items","placeholder":"Select Behaviour(s)","label":"name","multiple":"","options":_vm.behaviours,"reduce":function (btObj) { return btObj.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var bns = ref.bns;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(name))]),_c('span',{staticClass:"font-small-3 text-muted"},[_vm._v("BNs: "+_vm._s(bns.length))])])]}}]),model:{value:(_vm.selectedBehaviours),callback:function ($$v) {_vm.selectedBehaviours=$$v},expression:"selectedBehaviours"}})],1),_c('b-form-group',{attrs:{"label":"Interfaces","label-for":"select-int-items"}},[_c('v-select',{staticClass:"select-class",attrs:{"id":"select-int-items","placeholder":"Select Interface(s)","label":"name","multiple":"","options":_vm.interfaces,"reduce":function (intObj) { return intObj.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var display_id = ref.display_id;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(name))]),_c('span',{staticClass:"font-small-3 text-muted"},[_vm._v(_vm._s(display_id))])])]}}]),model:{value:(_vm.selectedInterfaces),callback:function ($$v) {_vm.selectedInterfaces=$$v},expression:"selectedInterfaces"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }