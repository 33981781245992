<template>
  <b-modal
    id="migrate-item-modal"
    size="lg"
    title="Select Collections to add item to"
    centered
    ok-label="Add"
    ok-variant="success"
    cancel-variant="outline-secondary"
    @show="getCollectionTree"
    @ok="migrate"
  >
    <div class="d-flex justify-content-between flex-wrap mb-50">
      <span class="h4">
        <span class="text-white">
          {{ `Moving ${node.label}: ` }}
        </span>
        <span class="text-secondary">
          {{ node.name || node.title }}
        </span>
      </span>
      <b-form-checkbox
        v-if="node.label === 'Collection'"
        v-model="deleteSrc"
        name="check-button"
        switch
      >
        Delete original after copying
      </b-form-checkbox>
    </div>
    <b-row>
      <b-col>
        <b-card class="scroll-box">
          <CollectionsTree
            :node="treeData"
            :begin-open="true"
            :selectable="true"
            @select="toggleCollection"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card class="scroll-box">
          <li v-for="coll in selectedCollections" :key="coll.id">
            <feather-icon
              icon="FolderIcon"
              class="mb-25 text-primary"
            />
            <span class="font-weight-bold h5 text-body">
              {{ coll.name }}
            </span>
            <feather-icon
              icon="XIcon"
              class="mb-25 text-danger remove cursor-pointer"
              @click="removeCollection(coll)"
            />
          </li>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import CollectionsTree from '@/components/Collections/CollectionTree.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'MigrateItem',
  components: {
    BFormCheckbox,
    CollectionsTree,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const treeData = ref({})
    const selectedCollections = ref([])
    const deleteSrc = ref(false)
    function getCollectionTree() {
      store.dispatch('collections/getCollectionTree', { items: false })
        .then(data => {
          treeData.value = data
          selectedCollections.value = []
        })
    }

    function toggleCollection(coll) {
      if (!selectedCollections.value.find(c => c.id === coll.id)) {
        selectedCollections.value.push(coll)
      } else {
        removeCollection(coll)
      }
    }

    function removeCollection(coll) {
      selectedCollections.value.splice(selectedCollections.value.indexOf(coll), 1)
    }

    function migrate() {
      const targets = selectedCollections.value.map(coll => coll.id)
      if (props.node.label === 'Collection') {
        const payload = {
          targets,
          collection: props.node.id,
          deleteSrc: deleteSrc.value,
        }
        store.dispatch('collections/move', payload)
          .then(() => {
            emit('migrated')
          })
      } else {
        const payload = {
          targets,
          item_ids: [props.node.id],
        }
        store.dispatch('collections/add', payload)
          .then(() => {
            emit('migrated')
          })
      }
    }

    return {
      selectedCollections,
      treeData,
      deleteSrc,
      getCollectionTree,
      toggleCollection,
      removeCollection,
      migrate,
    }
  },
}
</script>

<style scoped>

li {
  list-style-type: none;
}

.scroll-box {
  background-color: rgba(36, 43, 61, 1);
  height: 400px;
  overflow: auto;
  white-space: nowrap;
}

.remove {
  position: absolute;
  right: 1rem;
}

</style>
