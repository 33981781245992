<template>
  <b-modal
    id="create-collection-modal"
    title="Create Collection"
    no-fade
    no-close-on-backdrop
    ok-title="Create"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    :ok-disabled="loading"
    @show="clearFields"
    @ok="onSubmit"
  >
    <b-form-group label="Name" label-for="create-collection-name">
      <b-form-input
        id="create-collection-name"
        v-model="collectionName"
      />
    </b-form-group>
    <b-form-group label="Description" label-for="create-collection-description">
      <b-form-input
        id="create-collection-description"
        v-model="collectionDescription"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'CreateCollection',
  setup(props, { emit }) {
    const collectionName = ref('')
    const collectionDescription = ref('')
    const loading = ref(false)

    function onSubmit() {
      const payload = {
        name: collectionName.value,
        description: collectionDescription.value,
        parentId: store.state.collections.parent.id,
      }
      loading.value = true
      store.dispatch('collections/createCollection', payload)
        .then(() => emit('created'))
        .catch(e => console.error(e))
        .finally(() => {
          loading.value = false
        })
    }

    function clearFields() {
      collectionName.value = ''
      collectionDescription.value = ''
    }

    return {
      collectionName,
      collectionDescription,
      loading,
      onSubmit,
      clearFields,
    }
  },
}
</script>
