<template>
  <b-modal
    id="delete-collection-modal"
    title="Delete Collection"
    no-fade
    no-close-on-backdrop
    ok-title="Delete"
    ok-variant="danger"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    @ok="deleteCollection"
  >
    Are you sure you want to delete {{ collection.name }}?
  </b-modal>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'DeleteCollection',
  props: {
    collection: {
      type: Object,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    function deleteCollection() {
      const payload = {
        collId: props.collection.id,
        parent: props.parent,
      }
      store.dispatch('collections/deleteCollection', payload)
        .then(() => emit('deleted'))
    }

    return {
      deleteCollection,
    }
  },
}
</script>

<style scoped>

</style>
