<template>
  <b-spinner v-if="!node" />
  <li v-else class="line">
    <span v-if="node.has_child" @click="expanded = !expanded; $emit('expand', dropdownSize, expanded)">
      <feather-icon v-if="expanded" icon="ChevronDownIcon" class="mb-25 mr-25" />
      <feather-icon v-else icon="ChevronRightIcon" class="mb-25 mr-25" />
    </span>
    <span v-else style="padding-left: 1.25em" />
    <span
      class="h5 text-body user-select-none"
      @dblclick="$emit('open', node)"
    >
      <feather-icon
        v-if="node._type === 'Collection'"
        icon="FolderIcon"
        class="mb-25 text-primary icon"
      />
      <font-awesome-icon
        v-else-if="node._type === 'BehaviourTreeView'"
        :icon="['fas', 'vector-square']"
        class="icon text-info"
      />
      <font-awesome-icon
        v-else-if="node._type === 'Specification'"
        :icon="['fas', 'clipboard-list']"
        class="icon"
        style="color: rgb(66, 86, 66)"
      />
      <font-awesome-icon
        v-else-if="node._type === 'Interface'"
        :icon="['fas', 'bezier-curve']"
        class="icon"
        style="color: rgb(37, 68, 111)"
      />
      <span
        :class="{bold: node._type === 'Collection'}"
        @click="select(node)"
      >
        {{ node.name || node.title }}
      </span>
    </span>
    <span v-if="node._type === 'Collection'">
      <ul v-show="expanded">
        <template v-for="(child, index) in node.has_child">
          <CollectionsTree
            v-if="child.show || selectable"
            :key="index"
            :node="child"
            :focus="focus"
            :selectable="selectable"
            @expand="expand"
            @select="select"
            @open="openCollection"
          />
        </template>
      </ul>
    </span>
  </li>
</template>

<script>

import { ref, watch } from '@vue/composition-api'

export default {
  name: 'CollectionsTree',
  props: {
    node: Object,
    focus: String,
    beginOpen: Boolean,
    selectable: Boolean,
  },
  setup(props, { emit }) {
    const expanded = ref(false)
    expanded.value = props.beginOpen
    const dropdownSize = ref(0)
    dropdownSize.value = props.node.has_child?.length

    watch(() => props.focus, focus => {
      if (focus === props.node.id) expand(dropdownSize.value, expanded.value)
    })

    // bus functions to carry event back up to head of tree so parent component can receive the event
    function expand(childSize, exp) {
      if (exp) dropdownSize.value += childSize
      else dropdownSize.value -= childSize
      emit('expand', childSize, exp)
    }

    function select(selectedNode) {
      if (!props.selectable) return
      emit('select', selectedNode)
    }

    function openCollection(child) {
      emit('open', child)
    }

    return {
      expanded,
      dropdownSize,

      expand,
      select,
      openCollection,
    }
  },
}
</script>

<style scoped>

ul {
  --gutter: .5em;
  padding: 0 0 0 1em;
  list-style-type: none;
}

ul li {
  padding-left: var(--gutter);
}

li {
  list-style-type: none;
}

.bold {
  font-weight: bold;
}

.icon {
  height: 1em;
  width: 1em;
}

.line {
  position: relative;
  padding-bottom: 0.25em;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  border-left: 1px solid rgb(52, 61, 85);
  margin-top: 1.5em;
  margin-left: 0.5em;
}

</style>
