<template>
  <b-modal
    id="remove-item-modal"
    title="Remove Item"
    ok-title="Remove"
    ok-variant="danger"
    cancel-variant="outline-secondary"
    centered
    @ok="deleteItem"
  >
    <div class="mb-25">
      Are you sure you want to remove
      <span class="font-weight-bolder">{{ item.title || item.name }}</span>
      from the Collection?
    </div>
    <span class="text-small-2 text-muted font-italic">Item will not be deleted from the database.</span>
  </b-modal>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'DeleteItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    collection: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    function deleteItem() {
      const payload = {
        collId: props.collection.id,
        item: {
          itemId: props.item.id,
        },
      }
      store.dispatch('collections/removeItemFromCollection', payload)
        .then(() => emit('deleted'))
    }

    return {
      deleteItem,
    }
  },
}
</script>

<style scoped>

</style>
