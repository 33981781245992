<template>
  <b-modal
    id="add-item-modal"
    title="Add to Collection"
    no-close-on-esc
    no-fade
    no-close-on-backdrop
    @ok="addItems"
  >
    <!-- Specification select -->
    <b-form-group label="Specifications" label-for="select-spec-items">
      <v-select
        id="select-spec-items"
        v-model="selectedSpecifications"
        placeholder="Select Specification(s)"
        label="title"
        class="select-class"
        multiple
        :options="specifications"
        :reduce="specObj => specObj.id"
      >
        <template #option="{ title, prefix }">
          <div class="d-flex justify-content-between">
            <span>{{ title }}</span>
            <span class="font-small-3 text-muted">{{ prefix }}</span>
          </div>
        </template>
      </v-select>
    </b-form-group>

    <!-- Behaviour Tree select -->
    <b-form-group label="Behaviour Trees" label-for="select-bts-items">
      <v-select
        id="select-bts-items"
        v-model="selectedBehaviours"
        placeholder="Select Behaviour(s)"
        label="name"
        class="select-class"
        multiple
        :options="behaviours"
        :reduce="btObj => btObj.id"
      >
        <template #option="{ name, bns }">
          <div class="d-flex justify-content-between">
            <span>{{ name }}</span>
            <span class="font-small-3 text-muted">BNs: {{ bns.length }}</span>
          </div>
        </template>
      </v-select>
    </b-form-group>

    <!-- Interface select -->
    <b-form-group label="Interfaces" label-for="select-int-items">
      <v-select
        id="select-int-items"
        v-model="selectedInterfaces"
        placeholder="Select Interface(s)"
        label="name"
        class="select-class"
        multiple
        :options="interfaces"
        :reduce="intObj => intObj.id"
      >
        <template #option="{ name, display_id }">
          <div class="d-flex justify-content-between">
            <span>{{ name }}</span>
            <span class="font-small-3 text-muted">{{ display_id }}</span>
          </div>
        </template>
      </v-select>
    </b-form-group>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Discard
      </b-button>

      <b-button variant="success" @click="ok()">
        Add Item(s)
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  name: 'AddItem',
  components: {
    vSelect,
  },
  setup(props, { emit }) {
    // Start -- Specifications
    const selectedSpecifications = ref([])
    const specifications = ref([])
    function getSpecifications() {
      store.dispatch('specifications/fetchSpecificationsSimple')
        .then(() => {
          specifications.value = store.state.specifications.specifications
        })
    }
    getSpecifications()
    // End -- Specifications

    // Start -- Behaviour Trees
    const selectedBehaviours = ref([])
    const behaviours = ref([])
    function getBehaviours() {
      store.dispatch('behaviours/getAllBehaviourTrees')
        .then(() => {
          behaviours.value = store.state.behaviours.behaviourViews
        })
    }
    getBehaviours()
    // End -- Behaviour Trees

    // Start -- Interfaces
    const selectedInterfaces = ref([])
    const interfaces = ref([])
    function getInterfaces() {
      store.dispatch('interfaces/fetchInterfaces')
        .then(() => {
          interfaces.value = store.state.interfaces.interfaces
        })
    }
    getInterfaces()
    // End -- Interfaces

    function addItems() {
      const payload = {
        targets: [store.state.collections.parent.id],
        item_ids: [...selectedSpecifications.value, ...selectedBehaviours.value, ...selectedInterfaces.value],
      }
      store.dispatch('collections/add', payload)
        .then(() => emit('added'))
    }

    return {
      selectedSpecifications,
      specifications,
      selectedBehaviours,
      behaviours,
      selectedInterfaces,
      interfaces,

      addItems,
    }
  },
}
</script>

<style>
.select-class .vs__dropdown-option--selected {
  background: rgba(247, 153, 28, 0.82);
  color: #ffffff !important;
  /*font-weight: bolder;*/
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
